export const MainNavAdmin = [
  {
    icon: 'pe-7s-note2',
    label: 'Dashboard',
    to: '/dashboard',
  },
  {
    icon: 'pe-7s-config',
    label: 'Account Settings',
    to: '/settings',
  },
  {
    icon: 'pe-7s-piggy',
    label: 'Topup',
    to: '/topup',
  },
  {
    icon: 'pe-7s-print',
    label: 'Reports',
    content: [
      /*{
        label: 'Print History',
        to: '/print-report'
      },
      {
        label: 'Copy History',
        to: '/copy-report'
      },*/
      {
        label: 'Login History',
        to: '/login-history'
      }
    ]
  },
  {
    icon: 'pe-7s-tools',
    label: 'Mopri Settings',
    content: [
      {
        label: 'Company Settings',
        to: '/company-settings'
      },
      {
        label: 'Integration Settings',
        to: '/integration-settings'
      },
      {
        label: 'Payment Settings',
        to: '/payment-settings'
      },
      {
        label: 'User Management',
        to: '/user-management'
      },
      /*{
          label: 'Content Settings',
          to: '/content-settings'
      },
      {
          label: 'General Settings',
          to: '/general-settings'
      }*/
    ]
  }
];
