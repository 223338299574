export const MainNav = [
    {
        icon: 'pe-7s-note2',
        label: 'Dashboard',
        to: '/dashboard',
    },
    {
        icon: 'pe-7s-config',
        label: 'Account Settings',
        to: '/settings',
    },
    {
        icon: 'pe-7s-piggy',
        label: 'Topup',
        to: '/topup',
    },
    {
        icon: 'pe-7s-print',
        label: 'Reports',
        content: [
            /*{
                label: 'Print History',
                to: '/print-report'
            },
            {
                label: 'Login History',
                to: '/login-history'
            },*/
            {
                label: 'Login History',
                to: '/login-history'
            }
        ]
    }
];
