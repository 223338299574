import React, {lazy, Suspense} from 'react';
import { connect } from "react-redux";
import {Route, Switch, withRouter} from 'react-router-dom';
import Loader from 'react-loaders';
import ThemeOptions from "../ThemeOptions";


// Layout
import AppHeader from '../AppHeader/';
import AppSidebar from '../AppSidebar/';
import AppFooter from '../AppFooter/';


const MopriDashboard = lazy(() => import('../../Pages/Dashboards/Dashboard'));
const Login = lazy(() => import('../../Pages/LoginRegister/Login/Login'));
const Register = lazy(() => import('../../Pages/LoginRegister/Register/Register'));
const ForgotPassword = lazy(() => import('../../Pages/LoginRegister/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../../Pages/LoginRegister/ResetPassword/ResetPassword'));
const Logout = lazy(() => import('../../Pages/LoginRegister/Logout/Logout'));

// MOPRI SETTINGS
const Index = lazy(() => import('../../Pages/MopriSettings/SiteSettings'));
const IntegrationSettings = lazy(() => import('../../Pages/MopriSettings/IntegrationSettings'));
const PaymentSettings = lazy(() => import('../../Pages/MopriSettings/PaymentSettings'));
const UserManagement = lazy(() => import('../../Pages/MopriSettings/UserManagement'));
const ContentSettings = lazy(() => import('../../Pages/MopriSettings/ContentSettings'));
const GeneralSettings = lazy(() => import('../../Pages/MopriSettings/GeneralSettings'));
const AccountSettings = lazy(() => import('../../Pages/AccountSettings/AccountSettings'));
const TopupUpPage = lazy(() => import('../../Pages/Topup/Topup'));
const SnapScanPayment = lazy(() => import('../../Pages/Topup/Widgets/SnapScanPayment'));
const SticittPayment = lazy(() => import('../../Pages/Topup/Widgets/SticittPayment'));
const PayFastPayment = lazy(() => import('../../Pages/Topup/Widgets/PayFastPayment'));
const PayFastReturn = lazy(() => import('../../Pages/Topup/Widgets/PayFastReturn'));
const PayFastCancel = lazy(() => import('../../Pages/Topup/Widgets/PayFastCancel'));
const ElavonPayment = lazy(() => import('../../Pages/Topup/Widgets/ElavonPayment'));
const ElavonCancel = lazy(() => import('../../Pages/Topup/Widgets/ElavonCancel'));
const ElavonReturn = lazy(() => import('../../Pages/Topup/Widgets/ElavonReturn'));
const ElavonDecline = lazy(() => import('../../Pages/Topup/Widgets/ElavonDecline'));
const OpayoSuccess = lazy(() => import('../../Pages/Topup/Widgets/OpayoSuccess'));
const OpayoDecline = lazy(() => import('../../Pages/Topup/Widgets/OpayoDecline'));
const Faq = lazy(() => import('../../Pages/Faq'));

// MOPRI REPORTS
const LoginHistory = lazy(() => import('../../Pages/Reports/Widgets/LoginHistory'));
const PrintReport = lazy(() => import('../../Pages/Reports/PrintReport'));
const CopyReport = lazy(() => import('../../Pages/Reports/CopyReport'));


const LoadingMessage = () => (
    <div className="loader-container">
        <div className="loader-container-inner">
            <div className="text-center">
                <Loader type="ball-triangle-path" active/>
            </div>
            <br/>
            <br/>
            <h6 className="mt-3">
                Loading...
            </h6>
        </div>
    </div>
);

const AppMain = (props) => {
    if (props.isLoggedIn === true){
        return (
            <>
                <ThemeOptions/>
                <AppHeader />
                <div className="app-main">
                    <AppSidebar/>
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <Suspense fallback={<LoadingMessage/>}>
                                <Switch>
                                    <Route path="/" exact component={MopriDashboard}/>
                                    <Route path="/dashboard" component={MopriDashboard}/>
                                    <Route path="/settings" component={AccountSettings}/>
                                    <Route path="/topup" component={TopupUpPage}/>
                                    <Route path="/snapscan-confirm" component={SnapScanPayment}/>
                                    <Route path="/sticitt-confirm" component={SticittPayment}/>
                                    <Route path="/payfast-confirm" component={PayFastPayment}/>
                                    <Route path="/payfast-return" component={PayFastReturn}/>
                                    <Route path="/payfast-cancel" component={PayFastCancel}/>
                                    <Route path="/elavon-confirm" component={ElavonPayment}/>
                                    <Route path="/elavon-cancel" component={ElavonCancel}/>
                                    <Route path="/elavon-success" component={ElavonReturn}/>
                                    <Route path="/elavon-decline" component={ElavonDecline}/>
                                    <Route path="/opayo-success" component={OpayoSuccess}/>
                                    <Route path="/opayo-decline" component={OpayoDecline}/>

                                    <Route path="/company-settings" component={Index}/>
                                    <Route path="/integration-settings" component={IntegrationSettings}/>
                                    <Route path="/payment-settings" component={PaymentSettings}/>
                                    <Route path="/user-management" component={UserManagement}/>
                                    <Route path="/content-settings" component={ContentSettings}/>
                                    <Route path="/general-settings" component={GeneralSettings}/>
                                    {/*<Route path="/print-demo" component={PrintDemo}/>*/}
                                    <Route path="/help" component={Faq}/>

                                    <Route path="/print-report" component={PrintReport} />
                                    <Route path="/copy-report" component={CopyReport} />
                                    <Route path="/login-history" component={LoginHistory} />

                                        {/*<Route path="/terms" component={Terms}/>*/}
                                    <Route path="/logout" component={Logout}/>
                                </Switch>
                            </Suspense>
                        </div>
                        <AppFooter/>
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <Suspense fallback={<LoadingMessage/>}>
                <Switch>
                    <Route path="/" exact render={() => <Login/>}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/register" exact component={Register}/>
                    <Route path="/forgot-password" exact component={ForgotPassword}/>
                    <Route path="/reset-password/:token?/:uid?" exact component={ResetPassword}/>
                </Switch>
            </Suspense>
        )
    }
};

const mapStateToProps = state => ({
    isLoggedIn: state.userAuth.isLoggedIn,
    authenticated: state.userAuth.authenticated
});

export default withRouter(connect(mapStateToProps)(AppMain));
