import axios from 'axios';
import qs from 'qs';
import configureStore from "../config/configureStore";
import history from "../history";
import {NotificationManager} from 'react-notifications';
import{ init } from 'emailjs-com';
init("user_BWHiTy6gRmJRc50UVnQbN");


export const GET_USER = 'GET_USER';
export const START_LOGIN = 'START_LOGIN';
export const UPDATE_LOGO_URL = 'UPDATE_LOGO_URL';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const UPDATE_AUTH_LOGIN_STATE = 'UPDATE_AUTH_LOGIN_STATE';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const CHANGE_PRINTER_PIN = 'CHANGE_PRINTER_PIN';
export const CHANGE_PRINTER_PIN_ERROR = 'CHANGE_PRINTER_PIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const START_UPDATE_REALTIME_DATA = 'START_UPDATE_REALTIME_DATA';
export const FETCH_REALTIME_DATA = 'FETCH_REALTIME_DATA';

export const START_REGISTER = 'START_REGISTER';
export const CLIENT_REGISTERED = 'CLIENT_REGISTERED';
export const REGISTER_CLIENT = 'REGISTER_CLIENT';
export const REGISTER_COMPLETE = 'REGISTER_COMPLETE';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const VERIFY_EMAIL_ADDRESS = 'VERIFY_EMAIL_ADDRESS';
export const EMAIL_ADDRESS_NOT_VERIFIED = 'EMAIL_ADDRESS_NOT_VERIFIED';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';

export const UPDATE_AUTH_DETAILS_STATE = 'UPDATE_AUTH_DETAILS_STATE';

export const FETCH_MOPRI_PIN_SETTINGS = 'FETCH_MOPRI_PIN_SETTINGS';
export const UPDATE_COMPANY_LOGO_URL = 'UPDATE_COMPANY_LOGO_URL';
export const UPDATE_COUNTRY_LOCALIZATION = 'UPDATE_COUNTRY_LOCALIZATION';


export const verifyAuth = (dispatch) => {
//    dispatch(createAction(START_LOGIN));
   //const serializedState = localStorage.getItem('state');
    //console.log(serializedState);
//    console.log('running Verify Auth');
};

export function fetchLogoUrl(values) {
    return dispatch => {
        const url = `/api/settings/getmopriLogoUrl.asp?baseURL=${values}`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  UPDATE_LOGO_URL,
                    values: response
                });
                return response.logoURL
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const login = (values, dispatch) => {
    dispatch(createAction(START_LOGIN));
    // Start the login process
    axios({
        method: 'post',
        url: 'api/printdirector/mopri-login.asp',
        data: qs.stringify({
            email: values.userName,
            password: values.password
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            let errorMSG = '';
            if (response.data.failed === true) {
                errorMSG = "Username OR Password not valid";
                dispatch(createAction(LOGIN_ERROR, errorMSG));
            } else if (!response.data) {
                errorMSG = "There was a system failure while attempting to log you in.  Please notify your administrator";
                dispatch(createAction(LOGIN_ERROR, errorMSG));
            }
            else {
                let responseData = response.data
                dispatch(createAction(LOGIN, responseData));
                //console.log('success');
                history.push('/dashboard');
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};

export const register = (values, dispatch) => {
    dispatch(createAction(START_REGISTER));
    let regType = configureStore.getState().mopriSettings.systemIntegration.userRegistrationType;
    let selectedUserGroup = configureStore.getState().mopriSettings.systemIntegration.selectedUserGroup;
    let printerIntegrationID = configureStore.getState().mopriSettings.systemIntegration.printerIntegrationID;
    let siteID = configureStore.getState().mopriSettings.systemIntegration.siteID;
    let userFullName = values.firstNames+''+values.surname;
    let pdUserName;
    let url;
    if (regType === "Close") {
        if (printerIntegrationID === 1) {
            url = '/api/printdirector/pd-register.asp';
            pdUserName = userFullName.replace(/\s/g, '');
        } else if (printerIntegrationID === 2) {
            url = '/api/safecom/register.asp';
            pdUserName = values.pdUserName;
        }
    } else {
        if (printerIntegrationID === 1) {
            url = '/api/printdirector/selfregister.asp';
            pdUserName = userFullName.replace(/\s/g, '');
        } else if (printerIntegrationID === 2) {
            url = '/api/safecom/selfregister.asp';
            pdUserName = values.pdUserName;
        }
    }

    // post the register data to create the account
    axios({
        method: 'post',
        url: url,
        data: qs.stringify({
            firstName: values.firstNames,
            surname: values.surname,
            pdUserName: pdUserName,
            email: values.email,
            upw: values.registerPassword,
            selectedUserGroup: selectedUserGroup,
            siteID: siteID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            // Our Client has already registered
            if (response.data.accountExists) {
                dispatch(createAction(CLIENT_REGISTERED, response));
            }
            else {
                // Our client is a new registration
                dispatch(createAction(REGISTER_CLIENT, response));
                history.push('/login');
                //dispatch(push('#/login'));
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};

export const updatePassword = (values, dispatch) => {
    //console.log(values);
    axios({
        method: 'post',
        url: '/api/update-password.asp',
        data: qs.stringify({
            newPassword: values.newPassword,
            token: values.token,
            uid: values.uid,
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            // Add the response to state
            if (response.data.error === false) {
                dispatch(createAction(PASSWORD_RESET_SUCCESS, response.data));
                NotificationManager.success('Your password has successfully been updated.', 'Success');
                localStorage.clear();
                history.push('/login');
            } else {
                // Notify that there was an error
                NotificationManager.error('There was an error while updating your password.', 'Error');
            }
        })
        .catch((error) => {
            console.log(error);
        })
};

export const changeUserPassword = (values, dispatch) => {
    let userID = configureStore.getState().userAuth.mopriUID;

    axios({
        method: 'post',
        url: '/api/user-pw-reset.asp',
        data: qs.stringify({
            pw: values.password,
            userID: userID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            NotificationManager.success('Your password has successfully been updated.', 'Success');
        })
        .catch((error) => {
            NotificationManager.error('There was an error while updating your password.', 'Error');
        })
}

export const resetPassword = (values, dispatch) => {
    //let emailTopic = `Mopri Password Reset`;
    axios({
        method: 'post',
        url: '/api/pw-reset.asp',
        data: qs.stringify({
            recipient: values.email,
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            if (response.data.error === false) {
                // Send the Email Notification
                let clientDomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
                if (clientDomain === false) {
                    clientDomain = 'azdemo';
                }
                let requestURL = `https://${clientDomain}.mopri.net/reset-password/${response.data.encryptedReset}/${response.data.userID}`;

                setTimeout(function() {
                    let data = {
                        service_id: 'service_zfi8c2k',
                        template_id: 'template_r711i4s',
                        user_id: 'user_BWHiTy6gRmJRc50UVnQbN',
                        template_params: {
                            from_email: values.email,
                            pw_reset_link: requestURL
                        }
                    };

                   axios({
                       method: 'POST',
                       url: 'https://api.emailjs.com/api/v1.0/email/send',
                       data: data,
                       config: { headers: {'Content-Type': 'application/json' }}
                   })
                       .then((response) => {
                           if(response.data === 'OK' || response.status === 200) {
                                NotificationManager.success('If your email address exists then a password reset link has been sent to your mailbox.', 'Success');
                           } else {
                                NotificationManager.error('There was an error sending the password reset email.', 'Error');
                           }
                       })
                       .catch((error) => {
                           console.log(error);
                       });
                }, 3000);
            } else {
                // DO NOTHING
                //console.log(response.data);
            }
            // Display a message to the user
            NotificationManager.success('If your email account exists then your password reset email has been successfully sent', 'Success');
            dispatch(createAction(PASSWORD_RESET, response));
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while sending your password reset email', 'Error');
            dispatch(createAction(PASSWORD_RESET_ERROR, error));
        });
    return dispatch;
};

export function fetchMopriPinSettings() {
    return dispatch => {
        //let userName = configureStore.getState().userAuth.userName;
        const url = `/api/settings/getmopripinsettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_PIN_SETTINGS,
                    values: response
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const changeUserPIN = (values, dispatch) => {
    let pdUserName = configureStore.getState().userAuth.userName;
    let mopriUID = configureStore.getState().userAuth.mopriUID;

    axios({
        method: 'post',
        url: '/api/printdirector/printerpinreset.asp',
        data: qs.stringify({
            pdUserName: pdUserName,
            mopriUID: mopriUID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(CHANGE_PRINTER_PIN, response));
            NotificationManager.success('Your new Printer PIN has been generated', 'Success');
        })
        .catch((error) => {
            console.log(error);
            dispatch(createAction(CHANGE_PRINTER_PIN_ERROR, error));
            NotificationManager.error('There was an error while generating your new user PIN', 'Error');
        });

    return dispatch;
};

export const getRealtimeData = () => {
    let userName = configureStore.getState().userAuth.userName;
    let siteID = configureStore.getState().userAuth.siteID;
    let mopriUID = configureStore.getState().userAuth.mopriUID;

    return dispatch => {
        dispatch(createAction(START_UPDATE_REALTIME_DATA, null));
        axios({
            method: 'post',
            url: '/api/printdirector/getrealtimedata.asp',
            data: qs.stringify({
                userName: userName,
                siteID: siteID,
                mopriUID: mopriUID
            }),
            config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
        })
            .then(function (response) {
              //  console.log(response);
                dispatch(createAction(FETCH_REALTIME_DATA, response));

            })
            .catch(function (error) {
                console.log(error);
            });
    };
};

export const logout = (values, dispatch) => {
    dispatch(createAction(LOGOUT));
    localStorage.clear();
    history.push('/login');
   // NotificationManager.success('You have been successfully logged out.', 'Success');
};

export function clearLogin () {
    return dispatch => {
        localStorage.clear();
        dispatch({
            type: LOGOUT
        })
    }

}


export const createAction = (type, values) => {
    return {
        type,
        values
    };
};
