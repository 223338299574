import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory';
import rootReducer from './../Reducers/index';
import { loadState } from '../Utils/SessionStorage/SessionStorage';

//export const history = createBrowserHistory();
export const history = createHistory();

const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history)
];
//TODO: Hide redux dev tools for prod builds
//
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
}
//}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const persistedState = loadState();

const configureStore = createStore(
    connectRouter(history)(rootReducer),
    persistedState,
    composedEnhancers
);
//console.log('dispatch verify auth from STORE...');
//configureStore.dispatch(verifyAuth);

export default configureStore
