import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import configureStore from './config/configureStore';
import { saveState } from './Utils/SessionStorage/SessionStorage';
//import * as serviceWorker from './serviceWorker';
//import HttpsRedirect from 'react-https-redirect';
import history from "./history";
import './assets/base.scss';
import Main from './app';


configureStore.subscribe(() => {
    saveState(configureStore.getState());
});


ReactDOM.render(
    <Provider store={configureStore}>
        <Router history={history}>
            <Main />
        </Router>
    </Provider>,
    document.getElementById('root'));
//serviceWorker.register();
