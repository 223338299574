import React from 'react';


class AppFooter extends React.Component {
    getYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <>
                <div className="app-footer">
                    <div className="app-footer__inner">
                        <div className="app-footer-left">
                            © {this.getYear()} Mopri | Version: {process.env.REACT_APP_VERSION}
                        </div>
                        <div className="app-footer-right">

                        </div>
                    </div>
                </div>
            </>
        )}
}

export default AppFooter;
