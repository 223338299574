import {
    START_FETCH_PRINT_HISTORY,
    FETCH_PRINT_HISTORY_COMPLETE,
    FETCH_PRINT_HISTORY_ERROR
} from '../Actions/ReportActions';


const initialState = {
    isFetching: false,
    printHistory: [],
    copyHistory: []
};


export default (state = initialState, action) => {
    switch (action.type) {

        case START_FETCH_PRINT_HISTORY:
            return {
                ...state,
                isFetching: true,
                printHistory: [],
                copyHistory: []
            };

        case FETCH_PRINT_HISTORY_COMPLETE:
            return {
                ...state,
                isFetching: false,
                printHistory: action.payload.printHistory,
                copyHistory: action.payload.copyHistory
            };

        case FETCH_PRINT_HISTORY_ERROR:
            return {
                ...state,
                isFetching: true,
                printHistory: null,
                copyHistory: null
            };

        default:
            return state
    }
};
