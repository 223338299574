import {
    FETCH_MOPRI_GATEWAYS,
    FETCH_MOPRI_GATEWAY_DATA,
    FETCH_MOPRI_INTEGRATION_DATA,
    UPDATE_GATEWAYS,
    START_UPDATE_PRINTER_INTEGRATION,
    UPDATE_PRINTER_INTEGRATION,
    FETCH_MOPRI_REGISTRATION_DATA,
    FETCH_ACTIVE_DIRECTORY_USERS,
    UPDATE_FEES_SETTINGS, UPDATE_SNAPSCAN_SETTINGS,
    FETCH_SITE_USERS,
    UPDATE_SITE_USERS,
    UPDATE_SITE_SETTINGS,
    FETCH_COMPANY_SETTINGS,
    UPDATE_USER_GROUP,
    UPDATE_USER_GROUPS,
    UPDATE_GATEWAY_SETTINGS,
    ADD_OPAYO_MERCHANT_SESSION_KEYS
} from '../Actions/MopriSettingActions';


const initialState = {
    internationalSettings: {
        countryCode: null,
        countryCurrency: null
    },
    systemIntegration: {
        isfetchingSoftwareIntegrations: null,
        printerIntegrationID: null,
        pinRegister: null,
        generatePinCode: null,
        changePinCode: null,
        useActiveDirectory: null,
        userRegType: null,
        regURL: null,
        saveSuccess: null,
        saveFailed: null,
        saveErrorMsg: null,
        lastAdminUpdated: null,
        lastUpdateDate:null,
        adAdminUpdate: null,
        adAdminUpdateDate: null,
    },
    gateways: {},
    gatewaySettings: {
        snapScanActive: null,
        snapScanMerchID: null,
        snapScanAPIKey: null,
        snapScanEndpoint: null,
        snapScanVisaComm: null,
        snapScanVisaActive: null,
        payFastActive: null,
        payFastMerchId: null,
        payFastAPIKey: null,
        payFastEFTComm: null,
        payFastEFTFee: null,
        payFastEFTActive: null,
        payFastCcardComm: null,
        payFastCcardFee: null,
        payFastCcardActive: null,
        payFastMasterPassComm: null,
        payFastMasterPassFee: null,
        payFastMasterPassActive: null,
        payFastVisaElectronComm: null,
        payFastVisaElectronFee: null,
        payFastVisaElectronActive: null,
        payFastZapperComm: null,
        payFastZapperActive: null,
        payFastMobicredComm: null,
        payFastMobicredActive: null,
        payFastScodeComm: null,
        payFastScodeFee: null,
        payFastScodeActive: null,
        saveSuccess: false,
        saveSuccessMsg: null,
        saveFailed: false,
        saveFailMsg: null,
        feeStructure: null,
        gatewayActive: false,
        payFastSandbox: null,
        minTopupAmount: null,
        convergeUserID: null,
        elavonTerminalId: null,
        elavonSandbox: null,
        elavonMerchId: null,
        elavonActive: null
    },
    systemUsers: [],
    startFetchingSystemUsers: null,
    companySettings: []
};


export default (state = initialState, action) => {
    switch (action.type) {

        case FETCH_MOPRI_GATEWAYS:
            return {
                ...state,
                gateways: {
                    ...action.payload.gateways
                }
            }

        case FETCH_MOPRI_GATEWAY_DATA:
            return {
                ...state,
                gatewaySettings: {
                    snapScanActive: action.payload.gatewaySettings.snapScanActive,
                    snapScanMerchID: action.payload.gatewaySettings.snapScanMerchID,
                    snapScanAPIKey: action.payload.gatewaySettings.snapScanAPIKey,
                    snapScanEndpoint: action.payload.gatewaySettings.snapScanEndpoint,
                    snapScanVisaComm: action.payload.gatewaySettings.snapScanVisaComm,
                    snapScanVisaActive: action.payload.gatewaySettings.snapScanVisaActive,
                    payFastActive: action.payload.gatewaySettings.payFastActive,
                    payFastMerchId: action.payload.gatewaySettings.payFastMerchId,
                    payFastAPIKey: action.payload.gatewaySettings.payFastAPIKey,
                    payFastEFTComm: action.payload.gatewaySettings.payFastEFTComm,
                    payFastEFTFee: action.payload.gatewaySettings.payFastEFTFee,
                    payFastEFTActive: action.payload.gatewaySettings.payFastEFTActive,
                    payFastCcardComm: action.payload.gatewaySettings.payFastCcardComm,
                    payFastCcardFee: action.payload.gatewaySettings.payFastCcardFee,
                    payFastCcardActive: action.payload.gatewaySettings.payFastCcardActive,
                    payFastMasterPassComm: action.payload.gatewaySettings.payFastMasterPassComm,
                    payFastMasterPassFee: action.payload.gatewaySettings.payFastMasterPassFee,
                    payFastMasterPassActive: action.payload.gatewaySettings.payFastMasterPassActive,
                    payFastVisaElectronComm: action.payload.gatewaySettings.payFastVisaElectronComm,
                    payFastVisaElectronFee: action.payload.gatewaySettings.payFastVisaElectronFee,
                    payFastVisaElectronActive: action.payload.gatewaySettings.payFastVisaElectronActive,
                    payFastZapperComm: action.payload.gatewaySettings.payFastZapperComm,
                    payFastZapperActive: action.payload.gatewaySettings.payFastZapperActive,
                    payFastMobicredComm: action.payload.gatewaySettings.payFastMobicredComm,
                    payFastMobicredActive: action.payload.gatewaySettings.payFastMobicredActive,
                    payFastScodeComm: action.payload.gatewaySettings.payFastScodeComm,
                    payFastScodeFee: action.payload.gatewaySettings.payFastScodeFee,
                    payFastScodeActive: action.payload.gatewaySettings.payFastScodeActive,
                    saveSuccessMsg: null,
                    saveFailed: null,
                    saveFailMsg: null,
                    feeStructure: action.payload.gatewaySettings.feeStructure,
                    gatewayActive: true,
                    payFastSandbox: action.payload.gatewaySettings.payFastSandbox,
                    printerIntegrationID: action.payload.gatewaySettings.printerIntegrationID,
                    minTopupAmount: action.payload.gatewaySettings.minTopupAmount,
                    sticittActive: action.payload.gatewaySettings.sticittActive,
                    sticittSandbox: action.payload.gatewaySettings.sticittSandbox,
                    sticittMerchantID: action.payload.gatewaySettings.sticittMerchantID,
                    sticittMerchantName: action.payload.gatewaySettings.sticittMerchantName,
                    sticittMerchantAccountRef: action.payload.gatewaySettings.sticittMerchantAccountRef,
                    elavonActive: action.payload.gatewaySettings.elavonActive,
                    elavonMerchId: action.payload.gatewaySettings.elavonMerchId,
                    elavonSandbox: action.payload.gatewaySettings.elavonSandbox,
                    merchantPin: action.payload.gatewaySettings.merchantPin,
                    convergeUserID: action.payload.gatewaySettings.convergeUserID,
                    opayoActive: action.payload.gatewaySettings.opayoActive,
                    opayoProductionIntegrationKey: action.payload.gatewaySettings.opayoProductionIntegrationKey,
                    opayoProductionIntegrationPassword: action.payload.gatewaySettings.opayoProductionIntegrationPassword,
                    opayoProductionVendorName: action.payload.gatewaySettings.opayoProductionVendorName,
                    opayoSandboxIntegrationKey: action.payload.gatewaySettings.opayoSandboxIntegrationKey,
                    opayoSandboxIntegrationPassword: action.payload.gatewaySettings.opayoSandboxIntegrationPassword,
                    opayoSandboxVendorName: action.payload.gatewaySettings.opayoSandboxVendorName,
                    opayoSandbox: action.payload.gatewaySettings.opayoSandbox
                },
            };

        case FETCH_MOPRI_INTEGRATION_DATA:
            return {
                ...state,
                systemIntegration: {
                    isfetchingSoftwareIntegrations: false,
                    printerIntegrationID: action.payload.systemIntegration.printerIntegrationID,
                    printReleaseJobType: action.payload.systemIntegration.printReleaseJobType,
                    pinRegister: action.payload.systemIntegration.pinRegister,
                    generatePinCode: action.payload.systemIntegration.generatePinCode,
                    changePinCode: action.payload.systemIntegration.changePinCode,
                    useActiveDirectory: action.payload.systemIntegration.useActiveDirectory,
                    userRegType: action.payload.systemIntegration.userRegType,
                    regURL: action.payload.systemIntegration.regURL,
                    saveSuccess: action.payload.systemIntegration.saveSuccess,
                    saveErrorMsg: action.payload.systemIntegration.errorMsg,
                    lastAdminUpdated: action.payload.systemIntegration.lastAdminUpdated,
                    lastUpdateDate: action.payload.systemIntegration.lastUpdateDate,
                    userGroup: action.payload.systemIntegration.userGroup,
                    selectedUserGroup: action.payload.systemIntegration.selectedUserGroup
                },
                siteGroups: action.payload.siteGroups
            };

        case UPDATE_USER_GROUPS:
            return {
                ...state,
                siteGroups: action.siteGroups
        };

        case UPDATE_USER_GROUP:
            return {
                ...state,
                siteGroups: action.siteGroups
        };

        case UPDATE_GATEWAYS:
            return {
                ...state,
                gatewaySettings: {
                    snapScanActive: action.values.gatewaySettings.snapScanActive,
                    snapScanMerchID: action.values.gatewaySettings.snapScanMerchID,
                    snapScanAPIKey: action.values.gatewaySettings.snapScanAPIKey,
                    snapScanEndpoint: action.values.gatewaySettings.snapScanEndpoint,
                    snapScanVisaComm: action.values.gatewaySettings.snapScanVisaComm,
                    snapScanVisaActive: action.values.gatewaySettings.snapScanVisaActive,
                    payFastActive: action.values.gatewaySettings.payFastActive,
                    payFastMerchId: action.values.gatewaySettings.payFastMerchId,
                    payFastAPIKey: action.values.gatewaySettings.payFastAPIKey,
                    payFastEFTComm: action.values.gatewaySettings.payFastEFTComm,
                    payFastEFTFee: action.values.gatewaySettings.payFastEFTFee,
                    payFastEFTActive: action.values.gatewaySettings.payFastEFTActive,
                    payFastCcardComm: action.values.gatewaySettings.payFastCcardComm,
                    payFastCcardFee: action.values.gatewaySettings.payFastCcardFee,
                    payFastCcardActive: action.values.gatewaySettings.payFastCcardActive,
                    payFastMasterPassComm: action.values.gatewaySettings.payFastMasterPassComm,
                    payFastMasterPassFee: action.values.gatewaySettings.payFastMasterPassFee,
                    payFastMasterPassActive: action.values.gatewaySettings.payFastMasterPassActive,
                    payFastVisaElectronComm: action.values.gatewaySettings.payFastVisaElectronComm,
                    payFastVisaElectronFee: action.values.gatewaySettings.payFastVisaElectronComm,
                    payFastVisaElectronActive: action.values.gatewaySettings.payFastVisaElectronActive,
                    payFastZapperComm: action.payload.gatewaySettings.payFastZapperComm,
                    payFastZapperActive: action.payload.gatewaySettings.payFastZapperActive,
                    payFastMobicredComm: action.values.gatewaySettings.payFastMobicredComm,
                    payFastMobicredActive: action.values.gatewaySettings.payFastMobicredActive,
                    payFastScodeComm: action.values.gatewaySettings.payFastScodeComm,
                    payFastScodeFee: action.values.gatewaySettings.payFastScodeFee,
                    payFastScodeActive: action.values.gatewaySettings.payFastScodeActive,
                    saveSuccess: action.values.gatewaySettings.saveSuccess,
                    saveSuccessMsg: 'The settings where successfully saved',
                    saveFailed: action.values.gatewaySettings.saveFailed,
                    saveFailMsg: 'There was an error while trying to save the settings',
                    feeStructure: action.values.gatewaySettings.feeStructure,
                    gatewayActive: true,
                    payFastSandbox: action.values.gatewaySettings.payFastSandbox,
                    minTopupAmount: action.values.gatewaySettings.minTopupAmount,
                    sticittActive: action.values.gatewaySettings.sticittActive,
                    sticittSandbox: action.values.gatewaySettings.sticittSandbox,
                    sticittMerchantID: action.values.gatewaySettings.sticittMerchantID,
                    sticittMerchantName: action.values.gatewaySettings.sticittMerchantName,
                    sticittMerchantAccountRef: action.values.gatewaySettings.sticittMerchantAccountRef,
                    elavonActive: action.values.gatewaySettings.elavonActive,
                    elavonMerchId: action.values.gatewaySettings.elavonMerchId,
                    elavonSandbox: action.values.gatewaySettings.elavonSandbox,
                    elavonTerminalId: action.values.gatewaySettings.elavonTerminalId,
                    convergeUserID: action.values.gatewaySettings.convergeUserID,
                    opayoActive: action.values.gatewaySettings.opayoActive,
                    opayoProductionIntegrationKey: action.values.gatewaySettings.opayoProductionIntegrationKey,
                    opayoProductionIntegrationPassword: action.values.gatewaySettings.opayoProductionIntegrationPassword,
                    opayoProductionVendorName: action.values.gatewaySettings.opayoProductionVendorName,
                    opayoSandbox: action.values.gatewaySettings.opayoSandbox
                }
            };

        case START_UPDATE_PRINTER_INTEGRATION:
            return {
                ...state,
                systemIntegration: {
                    isfetchingSoftwareIntegrations: true,
                }
            };

        case UPDATE_PRINTER_INTEGRATION:
            return {
                ...state,
                systemIntegration: {
                    isfetchingSoftwareIntegrations: false,
                },
                ...action.values.data
            };


        case FETCH_MOPRI_REGISTRATION_DATA:  // Used for the registration process to show applicable form fields
            return {
                ...state,
                systemIntegration: {
                    siteID: action.payload.data.systemIntegration.siteID,
                    printerIntegrationID: action.payload.data.systemIntegration.printerIntegrationID,
                    pinRegister: action.payload.data.systemIntegration.pinRegister,
                    selectedUserGroup: action.payload.data.systemIntegration.selectedUserGroup,
                    userRegistrationType: action.payload.data.systemIntegration.userRegistrationType
                }
            };

        case FETCH_ACTIVE_DIRECTORY_USERS:
            return {
                ...state,
            };

        case UPDATE_FEES_SETTINGS:
            return {
                ...state,
                minTopupAmount: action.minTopupAmount,
                feeStructure: action.feeStructure
            };

        case UPDATE_SNAPSCAN_SETTINGS:
            return {
                ...state,
                snapScanActive: action.snapScanActive,
                snapScanMerchID: action.snapScanMerchID,
                snapScanAPIKey: action.snapScanAPIKey
            };

        case UPDATE_SITE_SETTINGS:
            return {
                ...state,
                companyName: action.values.companyName
            };

        case FETCH_COMPANY_SETTINGS:
            return {
                ...state,
                siteSettings: action.payload.data.siteSettings
            };

        case FETCH_SITE_USERS:
            return {
                ...state,
                systemUsers: action.payload.data.systemUsers
            };

        case UPDATE_SITE_USERS:
            return {
                ...state,
                systemUsers: action.values.systemUsers
            };

        case UPDATE_GATEWAY_SETTINGS:
            return {
                ...state,
                ...action.values
            };

        case ADD_OPAYO_MERCHANT_SESSION_KEYS:
            return {
                ...state,
                merchantSessionKey: action.values.merchantSessionKey
            }
        default:
            return state
    }
};
