import React, {Component} from 'react';
import {connect} from "react-redux";
import history from '../../../history';

import {logout} from '../../../Actions/AuthActions';

import {
    DropdownToggle, DropdownMenu,
    Button, UncontrolledButtonDropdown
} from 'reactstrap';

import {
    faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar from '../../../assets/utils/images/avatars/avatar.png';

class UserBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    doLogout() {
        logout()
    }

    render() {

        return (
            <>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={avatar} alt=""/>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div className="menu-header-image opacity-6"
                                                     style={{
                                                         backgroundImage: 'url(' + city3 + ')'
                                                     }}
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <img width={42} className="rounded-circle" src={avatar}
                                                                     alt=""/>
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading" style={{color: "#0b0b0b"}}>
                                                                    {this.props.userFirstName} {this.props.userLastName}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button className="btn-pill btn-shadow btn-shine"
                                                                        color="focus"
                                                                        onClick={() => {
                                                                            history.push('/logout');
                                                                        }}
                                                                >
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading" style={{color: "#0b0b0b"}}>
                                    {this.props.userFirstName} {this.props.userLastName}
                                </div>
                                <div className="widget-subheading"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    userFirstName: state.userAuth.userFirstName,
    userLastName: state.userAuth.userLastName,
});

export default connect(mapStateToProps)(UserBox);
