import {
    UPDATE_PMT_VALUE,
    CANCEL_TOPUP,
    SHOW_SNAPSCAN_QR_CODE,
    HIDE_SNAPSCAN_QR_CODE,
    CHANGE_PAYMENT_TYPE,
    RESET_TOPUP_AMOUNT,
    TOPUP_REQUESTED,
    REMOVE_SNAPSCAN,
    TOPUP_LOADED,
    UPDATE_PMT_TYPE,
    UPDATE_STICITT_TOKEN,
    UPDATE_STICITT_PAYMENT,
    VERIFY_STICITT_PAYMENT,
    COMPLETE_STICITT_PAYMENT,
    CLEAR_TOPUP_STATE,
    TOPUP_GUID,
    SHOW_3D_SECURE
} from '../Actions/MopriPaymentActions';

const initialState = {
    showCheckout: false,
    customPmtValue: 0,
    isLoading: false,
    showSnapScan: false,
    snapImage: null,
    orderId: null,
    showSnapScanSuccess: false,
    snapScanSuccessMsg: false,
    showSnapScanError: false,
    snapScanErrorMsg: null,
    pmtType: null,
    sticitt: [],
    opayo: [],
    show3DSecure: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case SHOW_SNAPSCAN_QR_CODE:
            return {
                ...state,
                showSnapScan: true,
                priceCents: action.values.priceCents,
                orderId: action.values.orderId
            };

        case HIDE_SNAPSCAN_QR_CODE:
            return {
                ...state,
                showSnapScan: false,
                priceCents: null,
                orderId: null
            };

        case CHANGE_PAYMENT_TYPE:
            return {
                ...state,
                showSnapScan: false
            };

        case RESET_TOPUP_AMOUNT:
            return {
                ...initialState
            };

        case COMPLETE_STICITT_PAYMENT:
            return {
                ...initialState,
                sticittTransactionStatus: action.values ? action.values.transactionStatus : null
            };

        case CANCEL_TOPUP:
            return {
                ...initialState
            };

        case UPDATE_PMT_TYPE:
            //console.log(action.values);
            return {
                ...state,
                pmtType: action.values.pmtType
            };


        case UPDATE_PMT_VALUE:
            // Convert the price to cents for SnapScan
        //    let priceCents = (action.values.customPmtValue * 100);
            // Generate a random order id
        //    let orderID = ('Mopri'+Math.floor((Math.random() * 100000000) +1));
            return {
                ...state,
                showCheckout: true,
                customPmtValue: action.values.customPmtValue
                /*showSnapScan: false,
                // snapImage: 'https://pos.snapscan.io/qr/BpWORtWM.svg?id='+orderID+'&amount='+priceCents+'&snap_code_size=150',  // PRODUCTION
                snapImage: 'https://pos.snapscan.io/qr/xrcV68NC.svg?id='+orderID+'&amount='+priceCents+'&snap_code_size=250&user='+action.values.userName,    // DEVELOPMENT
                orderId: orderID*/
            };

        case TOPUP_REQUESTED:
            return {
                ...state,
                isLoading: true,
                pmtType: action.values.pmtType
            };

        /*case TOPUP:
            /!*!// Convert the price to cents for SnapScan
            let priceCents = (action.price * 100);
            // Generate a random order id
            let orderID = ('Mopri'+Math.floor((Math.random() * 100000000) +1));*!/
            return {
                ...state,
                value: action.price,
                showSnapScan: false,
                // snapImage: 'https://pos.snapscan.io/qr/BpWORtWM.svg?id='+orderID+'&amount='+priceCents+'&snap_code_size=150',  // PRODUCTION
                snapImage: 'https://pos.snapscan.io/qr/xrcV68NC.svg?id='+orderID+'&amount='+priceCents+'&snap_code_size=150&user='+action.userName,    // DEVELOPMENT
                orderId: orderID
            };*/

        case TOPUP_LOADED:
            return {
                ...state,
                isLoading: false,
                showSnapScan: true,
            };

        case REMOVE_SNAPSCAN:
            return {
                ...state,
                value: 0,
                isLoading: false,
                showSnapScan: false,
                snapImage: ''
            };

        case UPDATE_STICITT_TOKEN:
            return {
                ...state,
                sticittToken: action.values
            };

        case UPDATE_STICITT_PAYMENT:
            return {
                ...state,
                sticitt: action.values,
            };

        case VERIFY_STICITT_PAYMENT:
            return {
                ...state,
            };

        case CLEAR_TOPUP_STATE:
            return {
                ...initialState,
            };

        case TOPUP_GUID:
            return {
                ...state,
                topupGUID: action.values,
            };

        case SHOW_3D_SECURE:
            return {
                ...state,
                show3DSecure: action.values.threeDSecure,
                paReq: action.values.paReq,
                cReq: action.values.cReq,
                acsUrl: action.values.acsUrl,
                paRes: action.values.paRes,
                statusCode: action.values.statusCode,
                transactionId: action.values.transactionId,
                status: action.values.status,
                acsTransId: action.values.acsTransId,
                dsTranId: action.values.dsTranId
            };

        default:
            return state
    }
};
