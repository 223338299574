import {
    START_LOGIN,
    UPDATE_LOGO_URL,
    LOGIN,
    LOGIN_ERROR,
    LOGOUT,
    START_REGISTER,
    REGISTER_CLIENT,
    CLIENT_REGISTERED,
    PASSWORD_RESET,
    PASSWORD_RESET_ERROR,
    CHANGE_PRINTER_PIN,
    CHANGE_PRINTER_PIN_ERROR,
    START_UPDATE_REALTIME_DATA,
    FETCH_REALTIME_DATA,
    PASSWORD_RESET_SUCCESS,
    FETCH_MOPRI_PIN_SETTINGS,
    UPDATE_COMPANY_LOGO_URL,
    UPDATE_COUNTRY_LOCALIZATION
} from '../Actions/AuthActions';


const initialState = {
    creditBalance: 0.00,
    isLoggingIn: false,
    isLoggedIn: false,
    loginError: null,
    userName: null,
    userPIN: null,
    email: null,
    resetPassword: false,
    resetPasswordMessage: null,
    isRegistering: false,
    showLoginErrorMsg: false,
    loginErrorMsg: null,
    accountExists: null,
    accountExistsMsg: null,
    accCreated: null,
    accCreatedMsg: null,
    isLoading: false,
    adminLevel: 0,
    editSiteSettings: false,
    mopriUID: null,
    userFirstName: '',
    userLastName: '',
    isRefreshingBalance: false,
    isRefreshingDashboard: false,
    isRefreshingTopupHistory: false,
    printerIntegrationID: null,
    printGraphHistory: null,
    updateUserPassword: null,
    updateUserPasswordMsg: null,
    pwUpdatefailed: null,
    pwUpdatesuccess: null,
    updateUserPIN: null,
    updateUserPinMsg: null,
    pinUpdatefailed: null,
    pinUpdatesuccess: null,
    totalPrinted: null,
    totalSevenDayPrinted: null,
    totalYesterdayPrinted: null,
    totalTodayPrinted: null,
    newUserPIN: null,
    showNewPin: false,
    logoURL: null,
    address1: null,
    address2: null,
    city: null,
    postalCode: null,
    country: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case START_LOGIN:
            return {
                ...state,
                creditBalance: 0.00,
                isLoggingIn: true,
                loginError: false,
                isLoggedIn: false,
                resetPassword: false,
                resetPasswordMessage: null,
                isRegistering: false,
                userName: null,
                showLoginErrorMsg: false,
                accountExists: false,
                accountExistsMsg: null,
                accCreated: null,
                accCreatedMsg: null,
                loginErrorMsg: null,
                adminLevel: 0,
                editSiteSettings: false,
                mopriUID: null
            };

        case UPDATE_LOGO_URL:
            return {
                ...state,
                logoURL: action.values.logoURL
            }

        case LOGIN:
            return {
                ...state,
                creditBalance: action.values.balance,
                loginError: null,
                isLoggedIn: action.values.loggedIn,
                resetPassword: false,
                resetPasswordMessage: null,
                isRegistering: false,
                userName: action.values.userName,
                showLoginErrorMsg: action.values.failed,
                loginErrorMsg: action.values.error,
                accountExists: false,
                accountExistsMsg: null,
                accCreated: false,
                accCreatedMsg: null,
                adminLevel: action.values.adminLevel,
                editSiteSettings: action.values.editSiteSettings,
                mopriUID: action.values.mopriUID,
                userFirstName: action.values.firstName,
                userLastName: action.values.lastName,
                email: action.values.email,
                printerIntegrationID: action.values.printerIntegrationID,
                printGraphHistory: action.values.printGraphHistory,
                totalPrinted: action.values.totalPrinted,
                totalSevenDayPrinted: action.values.totalSevenDayPrinted,
                totalYesterdayPrinted: action.values.totalYesterdayPrinted,
                totalTodayPrinted: action.values.totalTodayPrinted,
                isLoggingIn: false,
                totalPrintCost: action.values.totalPrintCost,
                totalSevenDayPrintedCost: action.values.totalSevenDayPrintedCost,
                totalYesterdayPrintedCost: action.values.totalYesterdayPrintedCost,
                totalTodayPrintedCost: action.values.totalTodayPrintedCost,
                newUserPIN: action.values.userPIN,
                showNewPin: action.values.showNewPin,
                localizationCountry: action.values.localizationCountry,
                localizationCurrency: action.values.localizationCurrency,
                logoURL: action.values.logoURL,
                address1: action.values.address1,
                address2: action.values.address2,
                city: action.values.city,
                postalCode: action.values.postalCode,
                country: action.values.country,
                telNumber: action.values.telNumber
            };

        case LOGIN_ERROR:
            return {
                ...state,
                isLoggingIn: false,
                loginErrorMsg: action.values,
                loginError: true,
                showLoginErrorMsg: true
            };

        case START_UPDATE_REALTIME_DATA:
            return {
                ...state,
                isRefreshingBalance: true,
                isRefreshingDashboard: true,
                isRefreshingTopupHistory: true,
            };

        case FETCH_REALTIME_DATA:
            return {
                ...state,
                isRefreshingBalance: false,
                //isRefreshingDashboard: false,
                isRefreshingTopupHistory: false,
                creditBalance: action.values.data.balance,
                /*totalPrinted: action.values.data.totalPrinted,
                totalSevenDayPrinted: action.values.data.totalSevenDayPrinted,
                totalYesterdayPrinted: action.values.data.totalYesterdayPrinted,
                totalTodayPrinted: action.values.data.totalTodayPrinted,
                printGraphHistory: action.values.data.printGraphHistory,
                totalPrintCost: action.values.data.totalPrintCost,
                totalSevenDayPrintedCost: action.values.data.totalSevenDayPrintedCost,
                totalYesterdayPrintedCost: action.values.data.totalYesterdayPrintedCost,
                totalTodayPrintedCost: action.values.data.totalTodayPrintedCost*/
            };

        case FETCH_MOPRI_PIN_SETTINGS:
            return {
                ...state,
                allowPINChange: action.values.data.allowPINChange
            };

        case LOGOUT:
            return {
                ...initialState
            };

        case START_REGISTER:
            return {
                ...state,
                isRegistering: true,
                isLoggingIn: false,
                isLoggedIn: false,
                accountExists: null,
                accountExistsMsg: null,
                loginError: null,
                accCreated: null,
                accCreatedMsg: null,
                showLoginErrorMsg: null
            };

        case CLIENT_REGISTERED:
            return {
                ...state,
                isLoggingIn: false,
                loginError: null,
                isLoggedIn: false,
                resetPassword: false,
                userFirstName: null,
                userLastName: null,
                email: null,
                userPIN: null,
                isRegistering: false,
                accountExists: true,
                accountExistsMsg: action.values.data.error,
                accCreated: false,
                accCreatedMsg: null,
                showLoginErrorMsg: false
            };

        case REGISTER_CLIENT:
            return {
                ...state,
                isLoggingIn: false,
                loginError: null,
                isLoggedIn: false,
                resetPassword: false,
                userFirstName: null,
                userLastName: null,
                email: action.values.data.email,
                userPIN: null,
                isRegistering: false,
                accountExists: JSON.parse(action.values.data.accountExists),
                accountExistsMsg: null,
                accCreated: true,
                accCreatedMsg: 'Your account has been created, please login',
                showLoginErrorMsg: false
            };

        case PASSWORD_RESET:
            return {
                ...state,
                passwordUpdated: action.values.data.success,
                passwordUpdatedMsg: action.values.data.updateMsg
            };

        case PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordUpdatedError: action.values.data.passwordUpdated,
                passwordUpdatedErrorMsg: action.values.data.passwordUpdatedErrorMsg
            };

        case CHANGE_PRINTER_PIN:
            return {
                ...state,
                newPINError: false,
                newPIN: action.values.data.userPIN,
                newPINSuccess: true
            };

        case CHANGE_PRINTER_PIN_ERROR:
            return {
                ...state,
                newPINError: true,
                newPinErrorMsg: 'There was an error while resetting your new Printer PIN Code'
            };

        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordUpdated: action.values.error,
                passwordUpdatedMsg: action.values.message
            };

        case UPDATE_COMPANY_LOGO_URL:
            return {
                ...state,
                logoURL: action.values.logoURL
            };

        case UPDATE_COUNTRY_LOCALIZATION:
            return {
                ...state,
                localizationCountry: action.values.localizationCountry,
                localizationCurrency: action.values.localizationCurrency
            }

        default:
            return state
    }
};
