import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import MetisMenu from 'react-metismenu';
import {bindActionCreators} from "redux";
import {MainNav} from './NavItems';
import {MainNavAdmin} from './NavItemsAdmin';
import { getRealtimeData} from "../../Actions/AuthActions";


class Nav extends Component {

    state = {};

    componentDidMount() {
        // set Interval
        this.interval = setInterval(this.props.getRealtimeData, 60000); // 60000 Refreshes data every minute
    }

    componentWillUnmount() {
        // Clear the interval right before component unmount
        clearInterval(this.interval);
    }

    render() {
        let navMenu;
        switch (this.props.adminLevel) {
            case 1:
            case 2:
                navMenu = MainNavAdmin;
                break;
            default:
                navMenu = MainNav
                break;
        }

        return (
            <>
                <h5 className="app-sidebar__heading"> </h5>
                <MetisMenu content={navMenu} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
            </>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

const mapStateToProps = state => ({
    adminLevel: state.userAuth.adminLevel
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getRealtimeData
}, dispatch);

Nav = connect(
    mapStateToProps,
    mapDispatchToProps
)(Nav);

export default withRouter(Nav);
