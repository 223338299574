import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';

import AuthReducer from './AuthReducer';
import ThemeOptions from './ThemeOptions';
import TopupReducer from './MopriPaymentReducer';
import MopriSettingsReducer from './MopriSettingReducer';
import ReportReducer from './ReportReducer';

import history from '../history';

const appReducer = combineReducers({
    router: connectRouter(history),
    userAuth: AuthReducer,
    topup: TopupReducer,
    mopriSettings: MopriSettingsReducer,
    reportData: ReportReducer,
    ThemeOptions,
    form: formReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }

    return appReducer(state, action);
};


export default rootReducer;

