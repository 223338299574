import axios from 'axios';
import configureStore from "../config/configureStore";
import qs from 'qs';
import {NotificationManager} from "react-notifications";
import {CLEAR_TOPUP_STATE} from "./MopriPaymentActions";

export const FETCH_MOPRI_GATEWAYS = 'FETCH_MOPRI_GATEWAYS';
export const FETCH_MOPRI_GATEWAY_DATA = 'FETCH_MOPRI_GATEWAY_DATA';
export const UPDATE_GATEWAYS = 'UPDATE_GATEWAYS';
export const UPDATE_SNAPSCAN_SETTINGS = 'UPDATE_SNAPSCAN_SETTINGS';
export const START_UPDATE_PRINTER_INTEGRATION = 'START_UPDATE_PRINTER_INTEGRATION';
export const UPDATE_PRINTER_INTEGRATION = 'UPDATE_PRINTER_INTEGRATION';
export const FETCH_MOPRI_REGISTRATION_DATA = 'FETCH_MOPRI_REGISTRATION_DATA';
export const FETCH_MOPRI_INTEGRATION_DATA = 'FETCH_MOPRI_INTEGRATION_DATA';
export const FETCH_ACTIVE_DIRECTORY_USERS = 'FETCH_ACTIVE_DIRECTORY_USERS';
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS';
export const UPDATE_FEES_SETTINGS = 'UPDATE_FEES_SETTINGS';
export const FETCH_SITE_USERS = 'FETCH_SITE_USERS';
export const UPDATE_SITE_USERS = 'UPDATE_SITE_USERS';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';
export const FETCH_COMPANY_SETTINGS = 'FETCH_COMPANY_SETTINGS';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const UPDATE_USER_GROUPS = 'UPDATE_USER_GROUPS';

export const ADD_OPAYO_MERCHANT_SESSION_KEYS = 'ADD_OPAYO_MERCHANT_SESSION_KEYS';
export const UPDATE_GATEWAY_SETTINGS = 'UPDATE_GATEWAY_SETTINGS';


export const updatePrinterIntegrations = (values, dispatch) => {
    dispatch(createAction(START_UPDATE_PRINTER_INTEGRATION, null));
    // Start the process to save the Printer Integration Settings.
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    axios({
        method: 'post',
        url: `/api/settings/saveprinterintegration.asp`,
        data: qs.stringify({
            printerIntegrationID: values.printerIntegrationID,
            printReleaseJobType: values.printReleaseJobType,
            useActiveDirectory: values.useActiveDirectory,
            userRegType: values.userRegType,
            generatePinCode: values.generatePinCode,
            changePinCode: values.changePinCode,
            adminUpdated: mopriUID,
            selectedUserGroup: values.selectedUserGroup ? values.selectedUserGroup : 0
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_PRINTER_INTEGRATION, response));
            NotificationManager.success('The printer integration settings where successfully saved.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while attempting to save the printer integration settings.', 'Error');
        });

    return dispatch
};

export const updatePrintGroup = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;

    axios({
        method: 'post',
        url: '/api/settings/updatePrintGroup.asp',
        data: qs.stringify({
            groupID: values.groupID,
            groupDescription: values.groupDescription,
            groupActive: values.groupActive,
            mopriUID: mopriUID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            if (response.data.groupUpdated === false) {
                NotificationManager.error('The Print User Group Description already exists', 'Error');
            } else {
                dispatch(createAction(UPDATE_USER_GROUP, response.data));
                NotificationManager.success('The Print User Group was successfully updated.', 'Success');
            }
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while attempting to update the Print User Group.', 'Error');
        });
};

export const updateFeesSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveFeesSettings.asp',
        data: qs.stringify({
            feeStructure: values.feeStructure,
            minTopupAmount: values.minTopupAmount
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_FEES_SETTINGS, response.data.gatewaySettings));
            NotificationManager.success('The payment settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while attempting to update the payment settings.', 'Error');
        });

    return dispatch
};


export const updateSnapScanSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveSnapScanSettings.asp',
        data: qs.stringify({
            snapScanActive: values.snapScanActive,
            snapScanMerchID: values.snapScanMerchID,
            snapScanAPIKey: values.snapScanAPIKey
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_SNAPSCAN_SETTINGS, response.data));
            NotificationManager.success('The SnapScan settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while attempting to update the SnapScan settings.', 'Error');
        });

    return dispatch
};

export const updateOpayoSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveOpayoSettings.asp',
        data: qs.stringify({
            opayoActive: values.opayoActive,
            opayoSandbox: values.opayoSandbox,
            opayoProductionVendorName: values.opayoProductionVendorName,
            opayoProductionIntegrationKey: values.opayoProductionIntegrationKey,
            opayoProductionIntegrationPassword: values.opayoProductionIntegrationPassword,
            opayoSandboxVendorName: values.opayoSandboxVendorName,
            opayoSandboxIntegrationKey: values.opayoSandboxIntegrationKey,
            opayoSandboxIntegrationPassword: values.opayoSandboxIntegrationPassword
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
            NotificationManager.success('The Opayo Payment Gateway settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error updating the Opayo Payment Gateway settings', 'Error');
        });
}

export const updateElavonSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveElavonSettings.asp',
        data: qs.stringify({
            elavonActive: values.elavonActive,
            elavonMerchId: values.elavonMerchId,
            merchantPin: values.merchantPin,
            convergeUserID: values.convergeUserID,
            elavonSandbox: values.elavonSandbox
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
            NotificationManager.success('The Elavon Payment Gateway settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error updating the Elavon Payment Gateway settings', 'Error');
        });
}

export const updatePayFastSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/savePayFastSettings.asp',
        data: qs.stringify({
            payFastActive: values.payFastActive,
            payFastSandbox: values.payFastSandbox,
            payFastMerchId: values.payFastMerchId,
            payFastAPIKey: values.payFastAPIKey,
            payFastEFTActive: values.payFastEFTActive,
            payFastCcardActive: values.payFastCcardActive,
            payFastMasterPassActive: values.payFastMasterPassActive,
            payFastVisaElectronActive: values.payFastVisaElectronActive,
            payFastZapperActive: values.payFastZapperActive,
            payFastMobicredActive: values.payFastMobicredActive,
            payFastScodeActive: values.payFastScodeActive
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data));
            NotificationManager.success('The PayFast settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error updating the PayFast settings', 'Error');
        });
    return dispatch;
};

export const updateSticittSettings = (values, dispatch) => {
    axios({
        method: 'post',
        url: '/api/settings/saveSticittSettings.asp',
        data: qs.stringify({
            sticittActive: values.sticittActive,
            sticittSandbox: values.sticittSandbox,
            sticittMerchantID: values.sticittMerchantID,
            sticittMerchantName: values.sticittMerchantName,
            sticittMerchantAccountRef: values.sticittMerchantAccountRef
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            //console.log(response);
            dispatch(createAction(UPDATE_GATEWAY_SETTINGS, response.data.gatewaySettings));
            NotificationManager.success('The Sticitt settings where successfully updated.', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error updating the Sticitt settings', 'Error');
        });
    return dispatch;
};

export function getPaymentGatewayDetails(id) {
    //console.log('id ', id);
    /*return dispatch => {
        const url = `/api/settings/getPaymentGateways.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_GATEWAYS,
                    payload: response
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }*/
}

export function fetchMopriGateways() {
    return dispatch => {
        const url = `/api/settings/getPaymentGateways.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_GATEWAYS,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function clearTopupState() {
    return dispatch => {
        dispatch({
            type: CLEAR_TOPUP_STATE
        })
    }
}

export function fetchMopriGatewaySettings() {
    return dispatch => {
        const url = `/api/settings/getmoprigatewaysettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_GATEWAY_DATA,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function fetchRegistrationDetails() {
    return dispatch => {
        const url = `/api/settings/getregistrationsettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type: FETCH_MOPRI_REGISTRATION_DATA,
                    payload: response
                })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
}

export function fetchMopriIntegrationSettings() {
    return dispatch => {
        //let userName = configureStore.getState().userAuth.userName;
        const url = `/api/settings/getsysintegrationsettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_MOPRI_INTEGRATION_DATA,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSiteUsers() {
    return dispatch => {
        const url = `/api/settings/getsiteusers.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_SITE_USERS,
                    payload: response
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}


export function addUserGroups(values, dispatch) {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    //console.log(values);

    axios({
        method: 'post',
        url: '/api/settings/addusergroups.asp',
        data: qs.stringify({
            mopriUID: mopriUID,
            newUserGroup: values.newUserGroup
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            if (response.data.groupAdded === false) {
                NotificationManager.error('The User Group already exists', 'Error');
            } else {
                dispatch(createAction(UPDATE_USER_GROUPS, response.data));
                NotificationManager.success('The new User Group was successfully saved.', 'Success');
            }
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while attempting to save the new User Group.', 'Error');
        });

    return dispatch;
}

export function doPrintDemo() {
   // return dispatch => {
    let userName = configureStore.getState().userAuth.userName;
        const url = `/api/settings/doprintdemo.asp?userName=${userName}`;
        axios.get(url)
            .then(() => {
                NotificationManager.success('Demo Print Submitted', 'Success');
            })
            .catch((error) => {
                console.log(error);
                NotificationManager.error('There was an error while submitting the Demo Print', 'Error');
            })
   // }
}

export function getCompanySettings() {
    return dispatch => {
        const url = `/api/settings/getcompanysettings.asp`;
        axios.get(url)
            .then(function (response) {
                dispatch({
                    type:  FETCH_COMPANY_SETTINGS,
                    payload: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export const updateLocalization = (values, dispatch) => {
    //console.log(values)
}

export const updateSiteSettings = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    //console.log(values);
    axios({
        method: 'post',
        url: '/api/settings/updatesitesettings.asp',
        data: qs.stringify({
            companyName: values.companyName,
            mopriUID: mopriUID,
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            dispatch(createAction(UPDATE_SITE_SETTINGS, response));
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};

export const updateGateways = (values, dispatch) => {
    // Start the process to save Merchant Gateway details
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    axios({
        method: 'post',
        url: '/api/putGatewaySettings.asp',
        data: qs.stringify({
            snapScanActive: values.snapScanActive,
            snapScanMerchID: values.snapScanMerchID,
            snapScanAPIKey: values.snapScanAPIKey,
            snapScanEndpoint: values.snapScanEndpoint,
            snapScanVisaComm: values.snapScanVisaComm,
            snapScanVisaActive: values.snapScanVisaActive,
            payFastActive: values.payFastActive,
            payFastMerchId: values.payFastMerchId,
            payFastAPIKey: values.payFastAPIKey,
            payFastEFTComm: values.payFastEFTComm,
            payFastEFTFee: values.payFastEFTFee,
            payFastEFTActive: values.payFastEFTActive,
            payFastCcardComm: values.payFastCcardComm,
            payFastCcardFee: values.payFastCcardFee,
            payFastCcardActive: values.payFastCcardActive,
            payFastMasterPassComm: values.payFastMasterPassComm,
            payFastMasterPassFee: values.payFastMasterPassFee,
            payFastMasterPassActive: values.payFastMasterPassActive,
            payFastVisaElectronComm: values.payFastVisaElectronComm,
            payFastVisaElectronFee: values.payFastVisaElectronFee,
            payFastVisaElectronActive: values.payFastVisaElectronActive,
            payFastBitcoinComm: values.payFastBitcoinComm,
            payFastBitcoinActive: values.payFastBitcoinActive,
            payFastMobicredComm: values.payFastMobicredComm,
            payFastMobicredActive: values.payFastMobicredActive,
            payFastScodeComm: values.payFastScodeComm,
            payFastScodeFee: values.payFastScodeFee,
            payFastScodeActive: values.payFastScodeActive,
            feeStructure: values.feeStructure,
            payFastSandbox: values.payFastSandbox,
            adminLastUpdated: mopriUID,
            minTopupAmount: values.minTopupAmount
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then(function (response) {
            dispatch(createAction(UPDATE_GATEWAYS, response.data));
        })
        .catch(function (error) {
            console.log(error);
        });

    return dispatch
};


export const updateMopriUser = (values, dispatch) => {
    let mopriUID = configureStore.getState().userAuth.mopriUID;
    axios({
        method: 'post',
        url: '/api/settings/userUpdate.asp',
        data: qs.stringify({
            firstName: values.firstName,
            lastName: values.lastName,
            userActive: values.userActive,
            userID: values.userID,
            adminLevelID: values.adminLevelID,
            adminUpdated: mopriUID
        }),
        config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' }}
    })
        .then((response) => {
            dispatch(createAction(UPDATE_SITE_USERS, response.data));
            NotificationManager.success('The users details have been updated', 'Success');
        })
        .catch((error) => {
            console.log(error);
            NotificationManager.error('There was an error while updating the users details', 'Error');
        })
};



//create_action
export const createAction = (type, values) => {
    return {
        type,
        values
    };
};
